const modal = document.getElementById("myModal");
const phoneName = document.getElementById("phone-name");
const nameErr = document.querySelector(".warning.phone-name")
const phone = document.getElementById("phone");
const phoneErr = document.querySelector(".warning.phone")

export function callTest(token) {
    [phoneName, phone].forEach(item => {
        item.addEventListener('change', () => {
            nameErr.style.display = 'none'
            phoneErr.style.display = 'none'
        })
    })
    modal.style.visibility = "visible";
    modal.style.display = "block"
    const section = document.getElementsByClassName('modal-content')
    const form = document.getElementsByClassName('modal-content__form')
    for (let i = 0; i < form.length; i++) {
        const allRadio = document.querySelectorAll('.modal-content__form ul li:first-child input')
        allRadio.forEach(item => {
            item.checked = true
        });

        if (i < 4) {
            form[i].lastElementChild.lastElementChild.onclick = function (e) {
                e.preventDefault()
                section[i].style.display = 'none'
                section[i + 1].style.display = 'block'
            }
            form[i].lastElementChild.firstElementChild.onclick = function (e) {
                e.preventDefault()
                if (i < 3) {
                    allRadio[i].checked = true
                }
                section[i].style.display = 'none'
                section[i - 1].style.display = 'block'
            }
        }

        if (i === 0) {
            form[i].lastElementChild.firstElementChild.onclick = function (e) {
                e.preventDefault()
                document.querySelectorAll('.modal-content__form input:checked').forEach(item => {
                    item.checked = false
                })
                modal.style.visibility = "hidden";
                modal.style.display = "none"
            }
        }
        if (i === 3) {
            let validName, validPhone, validCaptcha = false
            form[i].lastElementChild.lastElementChild.onclick = function (e) {
                e.preventDefault()

                function checkName() {
                    if (!phoneName.value || phoneName.value.length < 3 || phoneName.value.length > 30) {
                        validName = false
                        nameErr.style.display = "block"
                    } else {
                        validName = phoneName.value
                    }
                }

                checkName()

                function checkPhone() {
                    if (!phone.value.match(/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/)) {
                        validPhone = false
                        phoneErr.style.display = 'block'
                    } else {
                        validPhone = phone.value
                        validPhone = validPhone.replace(/[^0-9]/g, '')
                        validPhone = '+7' + validPhone.substring(1, validPhone.length)
                    }
                }

                checkPhone()

                function checkCaptcha() {
                    const captchaStr = token
                    if (!captchaStr) {
                        validCaptcha = false
                    } else {
                        validCaptcha = captchaStr
                    }
                }

                checkCaptcha()

                if (validName && validPhone && validCaptcha) {
                    phoneName.value = ''
                    phone.value = ''
                    section[section.length - 1].style.display = 'none'
                    section[0].style.display = 'block'
                    e.preventDefault()
                    document.querySelectorAll('.modal-content__form input:checked').forEach(item => {
                        item.checked = false
                    })
                    modal.style.visibility = "hidden";
                    modal.style.display = "none"

                    new Notify({
                        status: 'success',
                        title: 'Сообщение успешно отправлено!',
                        text: 'Спасибо за вашу заявку! В ближайшее время с вами свяжутся.',
                        effect: 'fade',
                        speed: 300,
                        customClass: null,
                        customIcon: null,
                        showCloseButton: true,
                        autoclose: true,
                        autotimeout: 3000,
                        gap: 20,
                        distance: 20,
                        type: 1,
                        position: 'right top'
                    })
                }
            }
        }
    }
}


// const screens = document.getElementsByClassName(".modal-content");

// for (let i = 0; i < screens.length; i++) {
//     console.log('one: ', screens[i])
// }
//
// console.log('screens: ', screens)